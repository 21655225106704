<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <div class="row mt-3" v-if="completedActivity.length">
        <div class="col-md-10">
          <ul class="timeline pt-3">
            <li v-for="(data, index) in completedActivity" :key="index">
              <time class="time">
                <span>{{ data.initiated_date | formatDate }}</span>
                <span>{{ data.initiated_date | formatTime }}</span>
              </time>
              <div class="icon bg-success">
                <i class="fas fa-user-md" style="color: white"></i>
              </div>
              <div class="label">
                <CRow>
                  <CCol md="9">
                    <CRow>
                      <CCol md="3">
                        <h6 class="text-muted">Job</h6>
                      </CCol>
                      <CCol md="9">
                        <span class="px-1">:</span>
                        <span>{{ data.job.job_title }}</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="3">
                        <h6 class="text-muted">Candidate</h6>
                      </CCol>
                      <CCol md="9">
                        <span class="px-1">:</span>
                        <span>{{ data.candidate.full_name }}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md="3" class="m-auto">
                    <span class="text-primary">
                      <span class="font-weight-light">Completed Date:</span>
                      <span class="pl-1 font-weight-bold d-block">{{
                        data.completed_date | formatDate
                      }}</span>
                    </span>
                  </CCol>
                </CRow>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p v-else class="text-muted h5 text-center p-4">No Completed Activity</p>
    </div>
  </div>
</template>
<script>
import m from "moment";
export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    completedActivity() {
      return (
        (this.activityList &&
          this.activityList.filter(
            ({ completed_date, completed_by, candidate_uid, job_id }) =>
              completed_date &&
              completed_by &&
              candidate_uid != null &&
              job_id != null
          )) ||
        []
      );
    },
  },
  filters: {
    formatTime(data) {
      if (data) return m.utc(data).local().format("hh:mm a");
      return "--";
    },
    formatDate(data) {
      if (data) return m.utc(data).local().format("Do MMM yyyy");
      return "--";
    },
  },
};
</script>