<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <div class="row mt-3" v-if="pendingActivity.length">
        <div class="col-md-10">
          <ul class="timeline pt-3">
            <li v-for="(data, index) in pendingActivity" :key="index">
              <time class="time">
                <span>{{ formatDate(data.initiated_date) }}</span>
                <span>{{ formatTime(data.initiated_date) }}</span>
              </time>
              <div class="icon bg-success">
                <i class="fas fa-user-md" style="color: white"></i>
              </div>
              <div class="label">
                <CRow>
                  <CCol md="9">
                    <CRow>
                      <CCol md="3">
                        <h6 class="text-muted">Job</h6>
                      </CCol>
                      <CCol md="9">
                        <span class="px-1">:</span>
                        <span>{{ data.job.job_title }}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md="3" class="m-auto">
                    <CButton color="primary" @click="openReview(data, index)">
                      Review
                    </CButton>
                  </CCol>
                </CRow>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p v-else class="text-muted h5 text-center p-4">No Pending Activity</p>
    </div>
  </div>
</template>
<script>
import m from "moment";
export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pendingActivity() {
      return (
        (this.activityList &&
          this.activityList.filter(
            ({ completed_date, completed_by, candidate_uid, job_id }) =>
              completed_date === null &&
              completed_by === null &&
              candidate_uid === null &&
              job_id != null
          )) ||
        []
      );
    },
  },
  methods: {
    formatTime(data) {
      return m.utc(data).local().format("hh:mm A") || "--";
    },
    formatDate(data) {
      return m.utc(data).local().format("Do MMM yyyy") || "--";
    },
    openReview(data, index) {
      const { action_detail_id } = data;
      this.$router.push({
        path: `job-authoriser-activity-review/${action_detail_id}`,
      });
    },
  },
};
</script>