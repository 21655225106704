<template>
  <CModal color="primary" :show.sync="isShowPopup" :closeOnBackdrop="false">
    <template #header>
      <h5>Pending Action Requests</h5>
      <CButtonClose @click="modalCallBack()" />
    </template>
    <template #footer>
      <CButton color="secondary" @click="modalCallBack()">Close</CButton>
    </template>
    <div>
      <table class="table">
        <thead>
          <th>User Type</th>
          <th>Count</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(data, index) in customerTypes" :key="index">
            <td>
              {{ data.label
              }}<small v-if="checkCurrentUserType(data)">(current)</small>
            </td>
            <td>{{ pendingActionCount(data) }}</td>
            <td>
              <CButton color="primary" @click="modalCallBack(data)"
                >Switch</CButton
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CModal>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    activityList: {
      type: Array,
      default: () => [],
    },
    customerTypes: {
      type: Array,
      default: () => [],
    },
    currentUserType: {
      type: Object,
      default: () => null,
    },
  },
  computed:{
    ...mapGetters(["userTypes"]),
  },
  methods: {
    pendingActionCount(customerType) {
      let checkingFields = {};
      if (customerType.code === 1) {
        checkingFields = {
          ...checkingFields,
          1: {
            candidate_uid: { isNull: false },
            job_id: { isNull: false },
          },
        };
      }
      if (customerType.code === 4) {
        checkingFields = {
          ...checkingFields,
          4: {
            candidate_uid: { isNull: true },
            job_id: { isNull: false },
          },
        };
      }
      return (
        (
          this.activityList &&
          this.activityList.filter((val) => {
            let r = val.completed_date === null && val.completed_by === null;
            let r1 = false;
            for (const [key, value] of Object.entries(
              checkingFields[customerType.code]
            )) {
              r1 = value.isNull ? val[key] === null : val[key] != null;
              if (!r1) return;
            }
            return r & r1;
          })
        ).length || 0
      );
    },
    checkCurrentUserType(data) {
      return this.currentUserType?.code === data?.code;
    },
    modalCallBack(data) {
      this.$emit("modalCallBack", data);
    },
  },
};
</script>