<template>
  <div class="activity-list py-2">
    <LoadingOverlay v-if="isFetchingActivityList" />
    <div v-else>
      <h4 class="text-primary pb-2">Activity List</h4>
      <div v-if="isJobAuthoriser">
        <CTabs variant="tabs" :active-tab="0">
          <CTab title="New/Pending">
            <AuthoriserPendingActivity :activityList="activityList" />
          </CTab>
          <CTab title="Completed">
            <AuthoriserCompletedActivity :activityList="activityList" />
          </CTab>
        </CTabs>
      </div>
      <div v-else-if="isCandidateReviewer">
        <CTabs variant="tabs" :active-tab="0">
          <CTab title="New/Pending">
            <ReviewerPendingActivity :activityList="activityList" />
          </CTab>
          <CTab title="Completed">
            <ReviewerCompletedActivity :activityList="activityList" />
          </CTab>
        </CTabs>
      </div>
    </div>
    <UserTypeModal
      v-if="userTypeModal.isShowPopup"
      :isShowPopup="userTypeModal.isShowPopup"
      :activityList="activityList"
      :customerTypes="customerUserRoles"
      :currentUserType="currentUserType"
      @modalCallBack="userTypeModalCallBack"
    />
  </div>
</template>
<script>
import ReviewerPendingActivity from "@/containers/ActivityList/CandidateReviewer/PendingActivity";
import ReviewerCompletedActivity from "@/containers/ActivityList/CandidateReviewer/CompletedActivity";
import AuthoriserPendingActivity from "@/containers/ActivityList/JobAuthoriser/PendingActivity";
import AuthoriserCompletedActivity from "@/containers/ActivityList/JobAuthoriser/CompletedActivity";
import UserTypeModal from "@/containers/ActivityList/UserTypeModal";
import { mapActions, mapGetters } from "vuex";
import { Role } from "@/helpers/helper";

export default {
  components: {
    ReviewerPendingActivity,
    ReviewerCompletedActivity,
    AuthoriserPendingActivity,
    AuthoriserCompletedActivity,
    UserTypeModal,
  },
  data() {
    return {
      userTypeModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "activityList",
      "isFetchingActivityList",
      "getIsJobAuthoriser",
      "getIsCandidateReviewer",
      "getIsCandidateReviewer",
      "currentUserType",
      "customerUserRoles",
      "isShowUserTypeModal",
      "getUser"
    ]),
    isCandidateReviewer(){
      return this.currentUserType?.code === 1 || false
    },
    isJobAuthoriser(){
      return this.currentUserType?.code === 4 || false
    }
  },
  methods: {
    ...mapActions(["fetchActivityList", "fetchUserType"]),
    userTypeModalCallBack(data) {
      if (data) {
        this.$store.commit("SET_CURRENT_USER_TYPE", data);
      }
      this.userTypeModal.isShowPopup = false;
      this.$store.commit("SET_IS_SHOW_USER_TYPE_MODAL", false);
    },
  },
  async mounted() {
    let appendAction = [];
    let user_role = await this.getUser?.users_roles ? 
      this.getUser.users_roles[0].type_name : '';
    if (user_role === Role.jobAdvertiser) {
      this.$router.replace('/public/job/home');
      return;
    }
    appendAction = [...appendAction,this.fetchActivityList()];
    Promise.all(appendAction).then((res) => {
      this.userTypeModal.isShowPopup = true && this.isShowUserTypeModal;
    });
  },
};
</script>